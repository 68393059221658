<template>
  <div class="privacy">
    <!--    <div class="header"><img @click="back" class="back" src="https://cdn.cailu88.com/jingxuanshi/back_arrow.png" alt="">隐私政策</div>-->
    <div class="content">
      尊敬的用户：
      <br/>
      <br/>
      『鲸选师』应用是由杭州聚心选网络科技有限公司为您提供的一款专业社交电商导购APP，旨在为用户提供优质商品和服务。
      <br/>
      <br/>
      平台经营者（以下或称“我们”）深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，我们特制定本隐私政策（以下或称“本政策”）并提醒您：
      <br/>
      <br/>
      平台经营者（以下或称“我们”）深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，我们特制定本隐私政策（以下或称“本政策”）并提醒您：
      <br/>
      <br/>
      本政策适用于鲸选师的所有产品和服务，如我们及/或关联公司的产品或服务中未设置单独的隐私政策，且产品及/或服务之间存在关联的，则本政策同样适用于我们及/或关联公司的其他产品或服务，另有特殊说明除外。本政策不适用于第三方向您提供的产品和服务。
      <br/>
      <br/>
      请您在使用我们的服务前，仔细阅读并透彻理解本政策（条款前所列标题仅为检索定位之用，不视为条款主旨的概括，请您仔细阅读各条款的具体内容），<span
      class="underline">特别是以粗体/粗体下划线标识的条款。</span>若您对本政策或相关事宜有任何疑问、意见或建议的，请通过平台客服或本政策列明方式（电子邮件：<span class="bold">jingxuanshi@juanet.com</span>；电话：<span
      class="bold">0571-26295187</span>）向我们问询。若您点击同意本政策或以其他方式表示接受本政策或已实际下载、安装、注册、使用平台及/或平台服务的，则视为您认可并愿意受本政策约束。
      <br/>
      <br/>
      <div class="important">第一章 定义及术语</div>
      <br/>
      <span class="bold">平台经营者（或称“经营者”、“我们”）：</span>指经营平台的法律主体，您可随时查看平台公示的证照信息等以确定彼时的实际经营主体。
      <br/>
      <br/>
      <span class="bold">关联公司：</span>指平台经营者的所有附属企业（如分公司、子公司）或任何存在直接或间接控制关系或重大影响关系的企业，包括但不限于杭州聚心选网络科技有限公司。
      <br/>
      <br/>
      <span class="bold">平台服务（或称“服务”）：</span>指经营者基于互联网，通过平台向用户提供的各项服务。
      <br/>
      <br/>
      <span class="bold">平台规则：</span>指包括本协议及在平台已经发布及后续发布的全部规则、实施细则、推广行为规范、公告等为有效维持平台运行而制定的文件。
      <br/>
      <br/>
      <span class="bold">平台规则变更：</span>包括但不限于本协议条款的更改、其他平台规则的更改、发布新的平台规则等。
      <br/>
      <br/>
      <span class="bold">用户（或称“您”）：</span>按照平台要求完成注册及/或认证流程，获得平台账户的即为平台用户。
      <br/>
      <br/>
      <span class="bold">个人信息：</span>指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。
      <br/>
      <br/>
      <span class="bold">个人敏感信息：</span>指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息，包括身份证件号码、个人生物识别信息、银行账号、通信记录和内容、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息、<span
      class="bold">14</span>岁以下（含）儿童的个人信息等。
      <br/>
      <br/>
      <span class="bold">个人信息匿名化：</span>通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程；注：个人信息经匿名化处理后所得的信息不属于个人信息。
      <br/>
      <br/>
      <span class="bold">个人信息去标识化：</span>通过对个人信息的技术处理，使其在不借助额外信息的情况下，无法识别个人信息主体的过程。
      <br/>
      <br/>
      除另有约定外，本政策所用定义与《用户服务协议》中的定义具有相同的涵义。
      <br/>
      <br/>
      <div class="important">第二章 隐私政策</div>
      <br/>
      本隐私政策将从如下几个方面进行阐述，帮助您了解以下内容：
      <br/>
      <br/>
      1、我们如何收集和使用您的信息
      <br/>
      2、我们如何使用 Cookie 和同类技术
      <br/>
      3、我们如何共享、转让、公开披露您的信息
      <br/>
      4、我们如何保护您的个人信息
      <br/>
      5、您的权利
      <br/>
      6、您的个人信息如何在全球范围转移
      <br/>
      7、未成年人保护
      <br/>
      8、本政策如何更新
      <br/>
      9、如何联系我们
      <br/>
      <br/>
      <span class="bold">一、 我们如何收集和使用您的信息</span>
      <br/>
      <br/>
      在您使用平台服务时，我们需要/可能需要收集和使用您的个人信息，如果您不提供相关信息，您可能无法享受我们提供的全部或部分服务，分类如下：
      <br/>
      <br/>
      1、我们提供的部分业务功能，可能需要依赖部分信息才得以运行。您选择和使用该部分业务功能的，则需要向我们提供或允许我们收集相关的必要信息，否则，您将无法正常使用我们该部分业务功能。
      <br/>
      <br/>
      2、部分情形下，您可自主选择向我们提供或允许我们收集信息，这些信息并非该业务功能运行所必需，但这些信息对改善服务质量、研发新产品或服务等有非常重要的意义。我 们不会强制要求您提供，如您拒绝并不会影响您正常使用相应的业务功能。
      <br/>
      <br/>
      您理解并同意：
      <br/>
      <br/>
      3、不同用户选择使用的具体产品/服务范围存在差异，我们尽可能打造丰富的产品和服务均是为了满足用户的需求。考虑到不同业务功能相应的收集、使用的个人信息类型、范围等会有所区别，您可以根据自身的需求选择合适的产品/服务，具体的产品/服务功能以平台介绍、公布为准；
      <br/>
      <br/>
      4、为给您带来更好的产品和服务体验，我们正在努力改进我们的技术，我们可能会不时推出新的或优化后的功能，我们向您提供的功能和服务场景是不断迭代升级的，我们可能需要收集、使用新的个人信息或变更个人信息使用目的或方式。对此，我们将通过更新本政策、页面提示等方式另行向您说明对应信息的收集目的、范围及使用方式，并为您提供自主选择同意的方式，且在征得您明示同意后收集、使用。在此过程中，如果您有任何疑问、意见或建议的，您可通过我们提供的联系方式与我们联系，我们会尽快为您做出解答。
      <br/>
      <br/>
      在您使用以下业务功能时，我们收集和使用您的个人信息，并将以高度的勤勉、审慎义务对待这些信息：
      <br/>
      <br/>
      <span class="bold">（一）成为我们的用户</span>
      <br/>
      <br/>
      为获得平台账户，<span class="underline-bold">您需要提供手机号，我们将通过发送短信验证码的方式来验证您的身份是否有效。注册后，我们有权要求您绑定第三方账号（如微信等，我们可能会不定时存在更新），同时授权我们获取您绑定的第三方账号的相关信息（包括但不限于微信昵称、头像、地区及性别等）。</span>
      <br/>
      <br/>
      如果您不同意授权或拒绝我们获取上述信息，您将无法获得平台账户，及/或通过第三方账号登录平台。
      <br/>
      <br/>
      <span class="underline-bold">您理解并知悉，您在获得平台账户后，我们可能会向其他协助您使用平台服务及功能的用户展示您的上述个人信息[包括但不限于手机号、微信号、微信昵称、微信头像（在您已授权绑定第三方账号的前提下）]，您对此不持异议。我们充分尊重并理解您通过平台项下账户设置所作的操作，您有权通过访问“设置-账号及安全”进行关闭展示（具体关闭路径以平台内提供的为准）。</span>
      <br/>
      <br/>
      <span class="bold">（二）向您展示商品和服务信息，供您浏览、搜索、收藏等</span>
      <br/>
      <br/>
      <span class="underline-bold">在您使用平台服务的过程中，为了知悉您的账号状态、了解我们提供服务的适配性、向您提供更契合的页面展示和搜索结果，我们会根据您访问及/或使用中的具体操作，收集您使用我们的服务与/或产品的设备信息（包括设备名称、设备型号、设备MAC地址、设备识别码（IMEI）、操作系统和应用程序版本、语言设置、分辨率、服务提供商网络ID（PLMN）、应用软件安装列表等软硬件特征信息）、浏览器类型和设备所在位置相关信息（包括您授权的GPS位置以及网络定位等）。</span>
      <br/>
      <br/>
      另外，为实现平台功能，包括不断优化平台功能之目的，<span class="underline-bold">我们可能会留存您的搜索词条、收藏/关注列表等内容，便于了解您的偏好，从而为您推送契合的商品及/或服务。</span>
      <br/>
      <br/>
      <span class="underline-bold">请注意，单独的设备信息、搜索/收藏记录、服务日志信息等是无法识别特定自然人身份的信息，不属您的个人信息，我们无义务对该类信息按照个人信息的标准进行使用、保护，我们有权以任何目的来使用这些信息。</span>
      <br/>
      <br/>
      <span class="bold">（三）购买商品和/或服务</span>
      若您通过平台直接下单、购买商品及/或服务的，平台会自动生成您的订单，<span class="underline-bold">收集您在本次订单中预留的收货人姓名、收货地址以及手机号码，同时收集该订单中载明的订单号、您所购买的商品或服务信息、您应支付的货款金额及支付方式。</span>
      <br/>
      <br/>
      上述所有信息结合为您的“订单信息”，我们将使用您的订单信息来进行您的身份核验、确定交易、支付结算、完成配送、为您查询订单以及提供客服咨询与售后服务；我们还会使用您的订单信息来判断您的交易是否存在异常以保护您的交易安全。
      <br/>
      <br/>
      <span class="bold">
        （四）支付
      </span>
      <br/>
      <br/>
      您在使用平台服务中，若需要支付费用的，您有权选择支付方式（包括但不限于微信支付、支付宝支付等，有特殊约定除外）。<span class="bold">我们可能会在您授权的前提下，向支付机构提供订单编号、交易额等必要信息，以实现您能完成支付的目的。</span>
      <br/>
      <br/>
      <span class="bold">（五）帮助您完成提现</span>
      <br/>
      <br/>
      若您使用平台服务过程中产生收益的，在您符合平台预设条件的前提下，您发起提现申请，选择您认为合适的收款方式，<span class="bold">并按照我们的指示提供真实、准确的信息，如支付宝账户等信息</span>。如果您选择使用我们或者我们支持的第三方的金融服务，为了交易的安全性及可用性，<span
      class="bold">我们可能需要您提供实名认证信息并授权我们查询和使用您的个人信用信息。</span>
      <br/>
      <br/>
      平台可能会根据自身发展以及用户的实际需求不断地更新费用的支付方式，您需按照届时平台流程完成信息的填写与认证。<span class="bold">我们将尽可能保护您所提供的上述信息，但由您自行确认您所提供信息的准确性、合法性，否则由于您个人原因导致款项支付存在问题（包括但不限于错付等），由您个人承担。</span>
      <br/>
      <br/>
      <span class="bold">（六）客服及投诉反馈</span>
      <br/>
      <br/>
      当您与我们联系或提出争议纠纷处理申请时，为了保障您的账户及系统安全，<span class="underline-bold">我们需要您提供必要的个人信息以核验您的用户身份。</span>
      <span class="underline-bold">为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的通信/通话记录及相关内容（包括账号信息（手机号等）、您为了证明相关事实提供的其他信息，或您留下的联系方式信息），如果您针对具体服务进行咨询、投诉或提供建议的，我们会在取得您同意的前提下使用您的账号内信息。</span>
      为了提供服务及改进服务质量的合理需要，我们还可能使用您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息等。
      <br/>
      <br/>
      <span class="bold">（七）其他</span>
      <br/>
      <br/>
      <span class="underline-bold">1、如您提供的信息中含有其他用户的个人信息，在向我们提供这些个人信息之前，您需确保您已经取得合法的授权。如其中涉及未成年人的个人信息的，您需在发布或提供前取得对应未成年人监护人的同意，前述情形下监护人有权通过本政策列明的途径联系我们，要求更正或删除涉及未成年人的个人信息的内容。</span>
      <br/>
      <span class="bold">2、如您在使用平台服务中，我们需要您提供第三方平台相关信息或绑定第三方平台账户的（包括但不限于淘宝、拼多多、唯品会、京东账号等，届时以平台展示信息为准），您可按届时页面指示自行选择是否同意或授权。您对自行操作绑定的账号拥有完整的权利，您授予我们获取相关账户信息等不违背任何现行法律法规规定或您与其他第三方之间的约定（包括但不限于您在注册第三方平台账户时确认的用户服务协议、隐私协议等），否则，由此引发的一切责任均由您自行承担，若平台因此存在损失的，您有义务予以赔偿并使平台不受损失。</span>
      <br/>
      <span class="underline-bold">3、如我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，均会事先获得您的同意。</span>
      <br>
      如我们从第三方处间接获取您的信息的，我们会在收集前明确以书面形式要求该第三方在已依法取得您同意后收集个人信息，并向您告知共享的信息内容，且涉及敏感信息的在提供给我们使用前需经过您的明确确认，要求第三方对个人信息来源的合法性和合规性作出承诺，如第三方有违反行为的，我们会明确要求对方承担相应法律责任；同时，我们的专业安全团队对个人信息会进行安全加固（包括敏感信息报备、敏感信息加密存储、访问权限控制等）。我们会使用不低于我们对自身用户个人信息同等的保护手段与措施对间接获取的个人信息进行保护。
      <br/>
      4、征得授权同意的例外：
      <br>
      您知悉并认可，若出现以下情形的，我们收集、使用您的个人信息无需您的授权同意：
      <br>
      （1）与个人信息控制者履行法律法规规定的义务相关的；
      <br>
      （2）与国家安全、国防安全有关的；
      <br>
      （3）与公共安全、公共卫生、重大公共利益有关的；
      <br>
      （4）与犯罪侦查、起诉、审判和判决执行等有关的；
      <br>
      （5）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
      <br>
      （6）所涉及的个人信息是您自行向社会公众公开的；
      <br>
      （7）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
      <br>
      （8）根据您的要求签订和履行合同所必需的；
      <br>
      （9）维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；
      <br>
      （10）新闻单位为开展合法的新闻报道所必需的；
      <br>
      （11）学术研究机构出于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
      <br>
      （12）法律法规规定的其他情形。
      <br>
      <span class="underline-bold"> 请知悉，根据适用的法律，如我们对个人信息采取技术措施和其他必要措施进行处理（包括但不限于个人信息匿名化、去标识化），使得数据接收方无法重新识别特定个人且不能复原的，在此情况下，我们有权使用已经匿名化、去标识化），使得数据接收方无法重新识别特定个人且不能复原的，在此情况下，我们有权使用已经匿名化或者去标识化的信息进行任何商业目的使用，且无需另行向您通知并征得您的同意。</span>
      <br>5、若我们因客观原因不再向用户提供产品/服务，我们将及时停止继续收集您个人信息的活动，并将停止运营的通知以推送或公告等形式通知您，并对我们所获取的与已关停业务相关的个人信息进行删除或匿名化处理。
      <br>6、在您账户被注销后，我们将不再为您提供相关产品或服务，我们将根据法律法规之规定对您的个人信息进行删除或匿名化处理。
      <br/>
      <br/>
      <span class="bold">二、我们如何使用 Cookie 和同类技术</span>
      <br/>
      <br/>（一）Cookie
      <br/>
      <br/>
      为确保网站正常运转，我们会在您的计算机或移动设备上存储名为 Cookie 的小数据文件。Cookie 通常包含标识符、站点名称以及一些号码和字符。借助于 Cookie，网站能够存储您的偏好或购物篮内的商品等数据。
      <br><br>我们不会将 Cookie 用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。您可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止 Cookie 的功能。<span
      class="underline-bold">但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。</span>如需详细了解如何更改浏览器设置，请访问以下链接：Internet Explorer、Google
      Chrome、Mozilla Firefox、Safari 和 Opera。
      <br/>
      <br/>（二）网站信标和像素标签
      <br/>
      <br/>
      除 Cookie 外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的点击
      URL。如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。
      <br/>
      <br/>（三）Do Not Track（请勿追踪)
      <br/>
      <br/>
      很多网络浏览器均设有 Do Not Track 功能，该功能可向网站发布 Do Not Track 请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了 Do Not
      Track，那么我们的所有网站都会尊重您的选择。
      <br/>
      <br/>
      <span class="bold">三、我们如何共享、转让、公开披露您的信息</span>
      <br/>
      <br/>
      （一）共享
      <br>
      <br>
      我们不会向其他的公司、组织和个人共享您的个人信息，但以下情况除外：
      <br>
      <br>
      1、在获取明确同意的情况下共享。
      <br>
      2、在法定情形下的共享：根据法律法规规定、诉讼、争议解决需要，或按行政、司法机关依法提出的要求，对相关部门共享您的个人信息。
      <br>
      3、只有共享您的信息，才能提供您需要的服务，或处理您与他人的纠纷或争议。
      <br>
      4、与关联公司共享：<span class="underline-bold">为保障我们能通过平台向您提供产品和服务，推荐您可能感兴趣的信息或保护关联公司或其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会与我们的关联公司共享。</span>我们只会共享必要的个人信息，且受本政策中所声明目的的约束，如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。
      <br>
      5、您理解并知悉，我们的某些服务将由合作伙伴或通过第三方SDK相关技术提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，<span
      class="underline">授权合作伙伴只能接触到其履行职责所需信息，且无权将共享的个人信息用于与其提供的产品或服务无关的其他用途。对于涉及未成年人的个人信息的，我们不允许合作伙伴进行转委托。合作伙伴SDK或相关技术获取用户信息情况披露：</span><span
      class="red-underline" @click="jump">点此了解。</span>
      <br>
      <span class="underline">我们的合作伙伴目前包括如下类型：</span>
      <br>（1）委托我们进行推广的素材提供者、合作伙伴。我们有时会接受其他企业委托向使用我们产品/服务的用户进行推广，我们仅会向这些合作伙伴提供推广的覆盖面和有效性的信息，除非得到您的书面许可，共享您上传的信息，我们不会提供您的个人身份信息，我们会将这些信息进行去标识化或匿名化处理，以帮助他们在不会识别您个人身份的情况下更好的提升自身服务。
      <br>（2）支持我们产品/服务的合作伙伴、第三方平台。这些支持包括受我们委托提供的技术基础设施服务、分析我们服务的使用方式、衡量推广和服务的有效性、提供客户服务或进行学术研究和调查。
      <br/>
      <br/>
      (二) 转让
      <br>
      <br>
      我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
      <br><br>
      1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息。<br>
      2、根据相关法律法规、强制性的行政或司法要求所必须的情况下进行转让。<br>
      3、在涉及合并、收购、资产转让或破产清算情形时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
      <br><br>(三) 公开披露<br><br>
      我们仅会在以下情况下，公开披露您的个人信息：<br><br>
      1、获得您明确同意后；<br>
      2、基于法律的披露：<span class="underline-bold">在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。</span><br>
      (四) 共享、转让、公开披露个人信息时事先征得授权同意的例外<br>
      以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：<br>
      1、与个人信息控制者履行法律法规规定的义务相关的。<br>
      2、与国家安全、国防安全有关的。<br>
      3、与公共安全、公共卫生、重大公共利益有关的。<br>
      4、与犯罪侦查、起诉、审判和判决执行等有关的。<br>
      5、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的。<br>
      6、您自行向社会公众公开的个人信息。<br>
      7、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。<br>
      8、法律、行政法规规定的其他情形。<br><br>
      <span class="bold">四、 我们如何保护您的个人信息</span><br><br>
      （一）我们为保障您的信息安全，已采取符合业界标准、合理可行的安全防护措施保护您提供的个人信息安全，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。在您的浏览器与“服务”之间交换数据（如信用卡信息）时受 SSL
      加密保护；我们同时对我们网站提供 https
      安全浏览方式；我们已经建立了完善的网络安全防御体系，当平台受到外部的网络攻击、黑客、病毒袭击时，我们会对攻击行为进行及时主动拦截。涉及到用户的隐私、敏感数据我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
      <br><br>（二）我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非法律有强制的存留要求。而我们判断前述期限的标准包括：
      <br><br>1、完成与您相关的产品或服务目的、交易目的、维护相应交易及服务记录、应对您可能的查询或投诉；
      <br>2、保证我们为您提供产品及服务的安全和质量；
      <br>3、您是否同意更长的留存期间；
      <br>4、是否存在保留期限的其他特别约定。<br><br>
      <span class="underline-bold">在您的个人信息超出保留期间后，我们会根据适用法律的要求删除您的个人信息，或使其匿名化处理。</span><br><br>
      <span class="underline-bold">（三）互联网环境并非百分之百安全，尽管我们有这些安全措施，但请您理解在信息网络上不存在“完善的安全措施”。即使我们做出了很大努力，仍有可能无法杜绝对您的个人信息的非法访问（包括计算机病毒、网络入侵和攻击破坏等）。此外，由于无法判断他人的陈述是否属实，我们可能会被不可靠的、有误导性的或非法的信息所蒙蔽。我们有权根据前述情况中止、终止向您提供全部或部分服务。若我们存在法定过错的，我们将承担相应的法律责任。在不幸发生个人信息安全事件后，我们也将按照法律法规的要求，及时通过页面公示、站内信、平台公告或您预留的联系方式中的一种或多种告知您。</span><br><br>
      在通过平台产品或服务使用第三方平台服务时，您不可避免地要向交易对方或潜在的交易对方披露自己的个人信息，例如联络方式或联系地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息尤其是您的账户或密码发生泄露，请您立即联络平台客服，以便我们根据您的申请采取相应措施。
      <br><br>（四）在使用平台或第三方平台服务/产品时您保证不会进行任何破坏或试图破坏本平台、第三方平台的产品或服务、网络安全的行为（包括但不限于钓鱼，黑客，网站或空间中含有或涉嫌散播：病毒、木马、恶意代码，及通过虚拟服务器对其他网站、服务器进行涉嫌攻击行为如扫描、嗅探、ARP欺骗、DDoS等），否则由您承担一切不利后果。
      <br/>
      <br/>
      <span class="bold">五、您的权利</span>
      <br/>
      <br/>
      按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：
      <br/>
      <br/>
      （一）访问、更正您的个人信息
      <br/>
      <br/>
      您有权访问、更正您的个人信息，法律法规规定的例外情况除外，您可以通过以下方式进行：点击“我的——设置”进行访问及更正，平台可能会根据业务发展调整访问或更正方式，以平台届时公示内容为准。
      <br/><br/>如果您无法通过上述链接访问或更正这些个人信息，您可以随时通过平台客服及/或本政策列明的联系方式进行咨询。我们将在15天内回复您的访问请求。
      <br/><br/>对于您在使用我们的产品或服务过程中产生的其他个人信息，只要我们不需要过多投入，我们会向您提供。如果您想行使数据访问权，按照本政策列明方式进行咨询。
      <br/><br/>（二）删除您的个人信息
      <br/><br/>在以下情形中，您可以向我们提出删除个人信息的请求：
      <br/><br/>1、如果我们处理个人信息的行为违反法律法规；
      <br/>2、如果我们收集、使用您的个人信息，却未征得您的同意；
      <br/>3、如果我们处理个人信息的行为违反了与您的约定；
      <br/>4、如果您不再使用我们的产品或服务，或您注销了账号；
      <br/>5、如果我们不再为您提供产品或服务。
      <br/><br/>若我们决定响应您的删除请求，我们还将尽可能通知从我们处获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。
      <br/><br/><span class="underline-bold">当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。</span>
      <br/><br/>（三）改变您授权同意的范围
      <br/><br/>您可以通过删除信息、修改及/或解除绑定、取消授权等方式改变或收回您的授权同意。每个业务功能需要一些基本的个人信息才能得以完成。
      <br/>当您收回同意后，我们有权不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。
      <br/><br/>（四）个人信息主体注销账户
      <br/><br/>您随时自行在平台中申请注销此前注册的账户，或请求平台客服协助注销。
      <br/><br/>在注销账户之后，我们将停止为您提供产品或服务，并依据您的要求，删除您的个人信息，法律法规另有规定的除外。
      <br/><br/>（五）约束信息系统自动决策
      <br/><br/>在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将提供适当的救济方式。
      <br/><br/>（六）响应您的上述请求
      <br/><br/>为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
      <br/><br/>我们将在十五天内做出答复。如您不满意，还可以向相关机关投诉。
      <br/>对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
      <br/>在以下情形中，按照法律法规要求，我们将无法响应您的请求：
      <br/>1、与个人信息控制者履行法律法规规定的义务相关的；
      <br/>2、与国家安全、国防安全有关的；
      <br/>3、与公共安全、公共卫生、重大公共利益有关的；
      <br/>4、与犯罪侦查、起诉和审判等有关的；
      <br/>5、有充分证据表明您存在主观恶意或滥用权利的；
      <br/>6、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的
      <br/>7、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；
      <br/>8、涉及商业秘密的。
      <br/>
      <br/>
      <span class="bold">六、您的个人信息如何在全球范围转移</span>
      <br/><br/>
      我们在中华人民共和国境内运营中收集和产生的个人信息，原则上，存储在中国境内，以下情形除外：
      <br/>1、适用的法律有明确规定；
      <br/>2、获得您的明确授权；
      <br/>3、您个人通过互联网形式主动传播、转移的行为。
      <br/>面对上述情形，我们会尽可能在现有技术的基础上依据本政策对您的个人信息提供在中华人民共和国境内足够同等的保护。
      <br/><br/>
      <span class="bold">七、 未成年人保护</span>
      <br/><br/>我们非常重视未成年人的个人信息保护。<span class="underline-bold">原则上，我们不向未成年人提供产品/服务，我们仅向成年人提供平台产品/服务，在使用平台产品/服务时我们推定您具有相应的民事行为能力。如您为未成年人，在使用平台产品/服务前，我们要求您请您的父母或监护人仔细阅读本政策，并在征得您的父母或监护人同意的前提下使用我们的产品或服务或向我们提供信息，您及您的父母或监护人应依照法律规定承担因此而导致的一切后果。</span>
      <br/><br/><span class="underline-bold">对于经父母或监护人同意使用我们的产品或服务而收集未成年人的个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。</span>
      <br/><br/><span class="underline-bold">如您是未成年人的父母或监护人，请您关注您监护的未成年人是否是在取得您的授权同意之后使用我们的商品或服务。</span>如您对您所监护的未成年人的个人信息有疑问时，请通过本政策列明的联系方式与我们联系。
      <br/><br/>
      <span class="bold">八、本政策如何更新</span>
      <br/><br/>基于平台产品/服务不断地更新或与第三方平台的合作发生变化的，我们的隐私政策可能会进行变更。未经您明确同意我们不会限制您按照本政策所应享有的权利。
      <br/><br/>涉及隐私政策重大变更地，我们还会提供更为显著的通知（包括通过平台、App或客户端首页公示的方式进行通知甚至向您提供弹窗提示）。您同意定期查看隐私政策，以便您了解任何修改。您同意，在我们发布修订后的隐私政策或在我们的网站上提供通知后，您继续使用本服务即表示您接受修订后的隐私政策。如果您不同意隐私政策的条款，则不应使用该服务。
      <br/><br/>本政策所指的重大变更包括但不限于：
      <br/>1、我们的服务模式发生重大变化。如处理用户信息的目的、用户信息的使用方式等。
      <br/>2、我们在控制权、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等。
      <br/>3、个人信息共享、转让或公开披露的主要对象发生变化。
      <br/>4、您参与个人信息处理方面的权利及其行使方式发生重大变化。
      <br/>5、我们负责处理用户信息安全的责任部门、联络方式及投诉渠道发生变化时。
      <br/>6、个人信息安全影响评估报告表明存在高风险时。
      <br/><br/>我们还会将本政策的旧版本存档，供您查阅。
      <br/><br/>
      <span class="bold">九、 如何联系我们</span>
      <br/><br/>若您对隐私政策有任何疑问或者建议、意见，您可以通过以下方式与我们联系，一般情况下，我们将在15天内回复您的请求：
      <br/>1、您可以直接通过平台客服、工作人员与我们联系；
      <br/>2、若您发现个人信息可能或已被泄露，您可以通电子邮件：<span class="bold">jingxuanshi@juanet.com</span>、电话：<span class="bold"> 0571-26295187</span>
      投诉举报；
      <br/>3、联系人：杭州聚心选网络科技有限公司，联系地址：杭州市余杭区仓前街道文一西路1218号33幢303室（303室-35），联系电话：<span class="bold"> 0571-26295187</span>。
      <br/>
      <br/>
      如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向相关部门投诉举报。
      <br/>
      <br/>
      本声明自更新之日起生效
      <br/>
      最新更新日期：2020年10月15日
      <br/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index',
  mounted () {
    window.scrollTo({
      top: 0
    })
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    jump () {
      this.$router.push({ name: 'table' })
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
  }
}
</script>

<style scoped>
.privacy {
  padding-bottom: 34px;
}

.privacy .header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  position: sticky;
  font-size: 18px;
  top: 0;
  left: 0px;
  background: white;
  width: 375px;
  position: relative;
}

.privacy .header .back {
  width: 9px;
  height: 16px;
  position: absolute;
  left: 14px;
  bottom: 14px;
}

.privacy .content {
  margin-top: 18px;
  padding: 0 18px;
  font-size: 15px;
  color: #333333;
  line-height: 21px;
  color: #333333;
}

.privacy .content .important {
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  line-height: 22px;
  margin-bottom: 12px;
}

.privacy .content .bold {
  font-weight: bold;
  font-size: 15px;
}

.privacy .content .underline {
  font-size: 15px;
  text-decoration: underline;
}

.privacy .content .red-underline {
  font-size: 15px;
  color: red;
  text-decoration: underline red;
}

.privacy .content .underline-bold {
  font-weight: bold;
  font-size: 15px;
  text-decoration: underline;
}
</style>
